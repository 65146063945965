import { Controller } from 'stimulus'
import StateDomElement from 'helpers/stateDomElement'

/* Ce component est lié au controller stimulus/select_with_search_controller.js
Mais la config ne permet pas de les mettre dans le même sidecar
*/

export default class extends Controller {
  static targets = ['clearSelect', 'select']

  connect() {
    this.stateDom = new StateDomElement()
    setTimeout(() => {
      this.checkSelectActive()
    }, 0)
  }

  checkSelectActive() {
    if (this.selectTarget.tomselect.items.length > 0) {
      this.stateDom.addClass(this.selectTarget.closest('.select-wrapper'), ['is-active'])
      this.stateDom.showElTailwind(this.clearSelectTarget)
    }
  }

  clearSelect() {
    this.selectTarget.tomselect.clear()
    this.selectTarget.tomselect.clearOptions()
    this.stateDom.hideElTailwind(this.clearSelectTarget)
  }
}
