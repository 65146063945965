import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['triggerElement', 'content', 'emptyMessage', 'selectAllHousing']

  static values = {
    trigger: String
  }

  connect() {
    this.verifyEmptyOptions()
    this.getCheckboxes().forEach(checkbox => {
      checkbox.addEventListener('change', this.verifyEmptyOptions.bind(this))
    })
    this.toggle()
  }

  toggle() {
    this.contentTarget.classList.toggle('tw-hidden', this.triggerElementTarget.value != this.triggerValue)
    if (this.triggerElementTarget.value != this.triggerValue) {
      this.checkAll()
      this.selectAllHousingTarget.checked = true
    } else {
      this.selectAllHousingTarget.checked = false
    }
    this.verifyEmptyOptions()
  }

  getCheckboxes() {
    return this.contentTarget.querySelectorAll('input[type=checkbox]')
  }

  checkAll() {
    this.selectAllHousingTarget.checked = true
    const checkboxes = this.getCheckboxes()
    checkboxes.forEach(checkbox => {
      checkbox.checked = true
    })
  }

  uncheckAll() {
    this.selectAllHousingTarget.checked = false
  }

  verifyEmptyOptions() {
    const checkboxes = this.getCheckboxes()
    this.emptyMessageTarget.classList.remove('tw-hidden')
    this.selectAllHousingTarget.checked = true
    checkboxes.forEach(checkbox => {
      if (checkbox.checked) {
        this.emptyMessageTarget.classList.add('tw-hidden')
      }
      if (!checkbox.checked) {
        this.selectAllHousingTarget.checked = false
      }
    })
  }
}
