import { Controller } from 'stimulus'
import StateDomElement from '../helpers/stateDomElement'

export default class extends Controller {
  static targets = ['accordeonButton', 'accordeonRowTitle']
  stateDomElement = new StateDomElement()

  connect() {
    this.initializeToggleAccordeon()
  }

  /**
   * Initialize all switch toggle buttons
   */
  initializeToggleAccordeon() {
    if (this.hasAccordeonButtonTarget) {
      this.accordeonButtonTargets.forEach(button => {
        button.addEventListener('click', this.handleClick)
      })
    }
  }

  handleClick(e) {
    e.currentTarget.classList.toggle('accordeon__item--active')
    const parentsAccordeon = e.currentTarget.parentNode.querySelectorAll('.js-accordeon-revealed')
    parentsAccordeon.forEach(elem => elem.classList.toggle('active'))
  }

  openAccordeonItem(e) {
    const accordeonItemContent = e.currentTarget.parentNode.querySelector('#accordeonContent')
    this.stateDomElement.toggleClass(accordeonItemContent, 'tw-hidden')
  }
}
