/**
 * Get size of an Element, or apply available size or height to element
 */
export default class SizeElement {
  constructor() {}

  /**
   * Date 19/10/2024
   * Calculates the available height between the bottom of an
   * element and the bottom of the window
   * @param {HTMLDomElement} el - Element of the DOM, base of my calcul
   * @returns {number} La hauteur disponible en pixels
   */
  getAvailableHeightAfterEl(el) {
    if (!el) return 0

    // Get element dimensions and position
    const rect = el.getBoundingClientRect()
    const remainingHeight = window.innerHeight - rect.bottom

    return Math.max(0, remainingHeight)
  }

  /**
   * Applies the remaining height to a target element
   * @param {HTMLDomElement} ElTarget - The target element
   * @param {number} ElRef - Number of available pixels (height)
   */
  applyRemainingHeight(elTarget, availableHeight) {
    if (!elTarget) return
    elTarget.style.height = `${availableHeight}px`
  }
}
