import { Controller } from 'stimulus'
import StateDomElement from '../../../helpers/stateDomElement'

export default class extends Controller {
  static targets = []
  stateDomElement = new StateDomElement()
  connect() {}

  openChat() {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.open()
      this.stateDomElement.setAt(document.querySelector('body'), 'data-action', 'click->components--ui--chatflow#closeChat')
    }
  }

  closeChat(e) {
    if (e.target.closest('#hubspot-messages-iframe-container') || e.target.closest('#js-open-hs-chat')) {
      return
    }
    window.HubSpotConversations.widget.close()
  }
}
