import { Controller } from 'stimulus'
export default class extends Controller {
  static targets = ['progressBar', 'percentageTxt']
  static values = {
    percent: Number,
    id: Number
  }

  connect() {
    if (this.hasProgressBarTarget) this.progressBarTarget.style.width = this.percentValue + '%'
    this.channel = App.cable.subscriptions.create(
      { channel: 'Importers::Channels::ImportChannel', id: this.idValue },
      {
        received: data => {
          data.status === 'processing' ? (this.percentValue = data.percentage) : window.location.reload()
        }
      }
    )
  }

  /**
   * Refreshes the progress bar each time the attribute value is updated, and the text of percentage indicator
   */
  percentValueChanged() {
    if (this.hasProgressBarTarget) {
      this.progressBarTarget.style.width = this.percentValue + '%'
      this.percentageTxtTarget.innerText = this.percentValue
    }
  }

  #handleData(data) {
    this.percentValue = data
  }
}
