import { Controller } from 'stimulus'
import StateDomElement from 'helpers/stateDomElement'
export default class extends Controller {
  static targets = [
    'containerInput',
    'contentImportBox',
    'errorMsg',
    'importBtn',
    'input',
    'inputInfo',
    'importRunning',
    'spinner',
    'title',
    'titleFile',
    'titleFileTxt'
  ]
  stateDomElement = new StateDomElement()

  connect() {}

  /**
   * Check if type of file is .csv or not
   */
  checkTypeOfFile() {
    const inputFiles = this.inputTarget.files
    const inputFileType = inputFiles[0].type
    if (inputFileType === 'text/csv') {
      this.stateDomElement.hideMultipleElTailwind([this.inputInfoTarget, this.errorMsgTarget, this.contentImportBoxTarget])
      this.stateDomElement.showElTailwind(this.spinnerTarget)
      this.stateDomElement.addClass(this.containerInputTarget, ['tw-pointer-events-none'])
      setTimeout(() => {
        this.stateDomElement.hideElTailwind(this.spinnerTarget)
        this.stateDomElement.showElTailwind(this.contentImportBoxTarget)
        this.stateDomElement.removeClass(this.containerInputTarget, ['tw-pointer-events-none'])
        this.stateDomElement.enableEl(this.importBtnTarget)
      }, 1000)
      this.enableRunImport()
    } else {
      this.errorTypeOfFile()
    }
  }

  emptyInputFiles(inputFiles) {
    inputFiles.value = ''
  }

  /**
   * Enable button and run the function to add the title in import box
   */
  enableRunImport() {
    this.getAddTitleOfFile()
  }

  errorTypeOfFile() {
    this.stateDomElement.showElTailwind(this.errorMsgTarget)
    this.emptyInputFiles(this.inputTarget)
    if (this.titleTarget.classList.contains('tw-hidden')) this.returnStepOne()
  }

  /**
   * get name of File and add it in import box
   */
  getAddTitleOfFile() {
    const inputFiles = this.inputTarget.files
    const fileName = inputFiles[0].name
    this.stateDomElement.hideElTailwind(this.titleTarget)
    this.stateDomElement.showElTailwind(this.titleFileTarget)
    this.titleFileTxtTarget.innerText = fileName
  }

  /**
   * If csv file already load, and that user try to other file with bad format.
   * I show error message and return step one
   */
  returnStepOne() {
    this.stateDomElement.hideElTailwind(this.titleFileTarget)
    this.stateDomElement.showMultipleElTailwind([this.titleTarget, this.inputInfoTarget])
    this.stateDomElement.disableEl(this.importBtnTarget)
  }

  /**
   * User click on the button "Lancer l'import or Run Import". Display the progress bar and information text.
   */
  runImport() {
    this.stateDomElement.hideMultipleElTailwind([this.titleFileTarget, this.importBtnTarget])
    this.stateDomElement.showElTailwind(this.importRunningTarget)
    this.stateDomElement.addClass(this.containerInputTarget, ['tw-pointer-events-none'])
  }
}
