import { Controller } from 'stimulus'
import { createDiv } from '../../task-calendar/taskTooltipHelpers'
import tippy from 'tippy.js'
import Client from '../../api/jana/client'
import Kitsu from 'kitsu'
import { shortFormatStartAndEndDate } from 'utils/helpersDate'
import { capitalizeFirstLetter } from 'utils/helpersString'
import LocalStore from 'helpers/localStore'

export default class extends Controller {
  static targets = ['calendarContainer', 'loader', 'taskChangeView']
  static values = {
    filterDate: String,
    guest: String
  }

  api = new Client('tasks')
  events = []
  currentDate = null
  translations = {}

  async connect() {
    this.locale = window.I18n
    await this.setTranslations()
    this.isInitialRender = true
    this.currentDate = this.formatDateRequest(this.filterDateValue)
    this.initCalendar()
    this.tooltipInstance = ''
  }

  async setTranslations() {
    const response = await fetch(`/translation.json`)
    const translations = await response.json()
    this.translations = translations['tasks'][this.locale]
  }

  async updateData(date, kind) {
    this.currentDate = date
    const tasks = await this.fetchTasks(kind)
    return this.filterTasks(tasks)
  }

  async fetchTasks(kind) {
    const filters = {}
    if (kind) {
      filters.kind = kind
    }
    filters.date = this.currentDate
    if (this.kindValue) {
      filters.kind = this.kindValue
    }

    if (this.guestValue) {
      filters.guest = this.guestValue
    }

    const fetchApi = new Kitsu({
      baseURL: '/api'
    })

    let allTasks = []
    let offset = 0
    let fetchedData

    do {
      const { data } = await fetchApi.get('tasks', {
        params: {
          filter: filters,
          include: 'housing,taskRequests.user,stay,stay.guest,stay.housing',
          page: {
            offset: offset,
            limit: 100
          }
        }
      })
      fetchedData = data
      allTasks = allTasks.concat(fetchedData)
      offset += 100
    } while (fetchedData.length === 100) // If less than 100 items are fetched, it means we've reached the last page

    return allTasks
  }

  filterTasks(tasks) {
    return tasks.map(task => {
      const { start, allDay } = this.formatDate(task)
      return this.buildCalendarEvent(task, start, allDay)
    })
  }

  formatDate(task) {
    const date = new Date(task.date)
    const time = task.hour ? new Date(task.hour) : null
    let start
    let allDay = false

    if (time) {
      const timeString = time.toLocaleTimeString(this.locale, { hour12: false })
      start = new Date(`${date.toDateString()} ${timeString}`)
    } else {
      start = new Date(`${date.toDateString()}`)
      allDay = true
    }
    return { start, allDay }
  }

  buildCalendarEvent(task, start, allDay) {
    const users = task.pendingOrAcceptedUserRequestedFullNames
    const housingFullName = task.housingName === '-' ? 0 : task.housingName
    const usersId = users.length ? users.map(user => user.id) : [0]
    let stayData = task.stay?.data || 0
    if (stayData !== 0) {
      this.formatStayObjToShowInTooltip(stayData)
    }
    const event = {
      allDay,
      className: `event-${task.kind} status-${task.status} event-${task.id} event-task-calendar`,
      idName: 'eventTaskCalendar',
      extendedProps: {
        kind: this.translations[task.kind],
        title: task.kind == 'other' ? task.title : this.translations[task.kind],
        place: task.housing?.data?.namePrivate || task.housingName,
        users: users,
        eventId: task.id
      },
      id: task.id,
      resourceIds: usersId,
      start: allDay ? task.date : start.toISOString(),
      tooltip: {
        taskInfo: stayData !== 0 ? this.formatStayObjToShowInTooltip(stayData) : 0,
        title: task.title || this.translations[task.kind],
        housing: task.housing?.data?.namePrivate || housingFullName,
        assigned: task.pendingOrAcceptedUserRequestedFullNames,
        taskDescription: task.description
      }
    }

    if (task.duration && !allDay) {
      const minute = 60 * 1000
      const end = new Date(start.getTime() + task.duration * minute)
      event.end = end.toISOString()
    }
    return event
  }

  updateUrlWithDate(date) {
    if (this.isInitialRender) {
      this.isInitialRender = false
      return
    }
    const url = new URL(window.location.href)
    document.getElementById('sidebarDate')._flatpickr.setDate(date)
    const [day, month, year] = date.toLocaleDateString().split('/')
    const newDate = `${year}-${month}-${day}`
    url.searchParams.set('date', newDate)
    history.pushState(null, '', url)
    this.updateHrefDate(url, newDate)
  }

  updateHrefDate(url, newDate) {
    document.querySelector('#date').value = newDate
    document.querySelectorAll('#taskChangeView').forEach(element => {
      const href = element.getAttribute('href') || element.getAttribute('action')
      const url = new URL(window.location.origin + href)
      url.searchParams.set('date', newDate)
      element.setAttribute('href', url.pathname + url.search)
      element.setAttribute('action', url.pathname + url.search)
    })
  }

  handleDurationChange(event) {
    const durationMs = event.event.end.getTime() - event.event.start.getTime()
    const duration = durationMs / (60 * 1000)
    const body = { duration }

    this.handleEventChange(event, body)
  }

  handleTimeChange(info) {
    const newDate = new Date(info.event.start)
    const hour = info.event.allDay ? null : newDate.toLocaleTimeString(this.locale, { hour12: false })
    const body = { date: newDate.toDateString(), hour }
    this.handleEventChange(info, body)
  }

  async handleEventChange(event, body) {
    const taskId = event.event.id
    try {
      this.api.update({ ...body, id: taskId })
      setTimeout(() => {
        this.calendar.refetchEvents()
      }, 100)
    } catch (error) {
      event.revert()
    }
  }

  renderEventContent({ event, timeText }, createElement) {
    const title = createElement('p', { class: 'event__title fc-event-time' }, event.extendedProps.title)
    const time = createElement('p', { class: 'event__time' }, timeText)
    const place = createElement('p', { class: 'event__place' }, event.extendedProps.place)

    const content = [time, place]
    let users
    if (event.extendedProps.users?.length) {
      if (event.extendedProps.users.length == 1) {
        const fullName = event.extendedProps.users[0].pretty_name
        users = createElement('p', { class: 'event__people' }, fullName)
      } else if (event.extendedProps.users.length > 1) {
        users = createElement('p', { class: 'event__people event__people--multiple' }, `Multiple`)
      }
    } else {
      users = createElement('p', { class: 'event__people event__people--empty' }, `Non assigné`)
    }
    content.push(users)

    const contentNodes = createElement('div', { class: 'fc-event-title-container' }, content)
    return createElement('div', { class: 'event fc-event-main-frame' }, [title, contentNodes])
  }

  renderEventTooltip(data) {
    const tooltipContent = createDiv(data.event.extendedProps.eventId)
    const tooltipInstance = tippy(data.el, {
      content: tooltipContent,
      arrow: false,
      allowHTML: true,
      theme: 'white',
      placement: 'bottom',
      maxWidth: '292px',
      trigger: 'manual'
    })
    tooltipInstance.show()
    this.tooltipInstance = tooltipInstance
  }

  hideEventTooltip() {
    this.tooltipInstance.hide()
  }

  eventClick(info) {
    const baseUrl = `/hotels/jana-bnb-mode-bnb/management/tasks/${info.event.id}`
    load_module(baseUrl, 'task')
    info.jsEvent.preventDefault()
  }

  handleChangeView(view) {
    const url = this.updateUrlWithView(view)
    this.reloadTurboFrame(url)
  }

  updateUrlWithView(view) {
    const url = new URL(window.location.href)
    url.searchParams.set('view', view)
    history.pushState(null, '', url)
    return url
  }

  reloadTurboFrame(url) {
    const frame = document.getElementById('task_calendar')
    url.searchParams.set('format', 'turbo_stream')
    Turbo.visit(url, {
      action: 'update'
    })
  }

  formatDateRequest(date) {
    const dateObject = new Date(date)
    return dateObject.toLocaleDateString()
  }

  /**
   * Date 26/11/2024
   * Creates a string containing the name of the guest, the dates of the stay and the source
   * if the stay exists, as well as the underlying elements.
   * @param {Object} data - Stay data to be displayed in the tooltip
   * @returns Returns a string with concatenated data
   */
  formatStayStringToShowInTooltip(data) {
    let dataString = ''
    if (data.guest === undefined) {
      dataString = `${data.dates}, ${data.source}`
    } else if (data.source === undefined) {
      dataString = `${data.guest}, ${data.dates}`
    } else {
      dataString = `${data.guest}, ${data.dates}, ${data.source}`
    }
    return dataString
  }

  /**
   * Date 26/11/2024
   * @param {Object} data - Object who contains data of stay
   * @returns Returns an object with the necessary stay data for the tooltip
   */
  formatStayObjToShowInTooltip(data) {
    let stayInfos = {}
    const guestName = data.guestCalendarName || 0
    stayInfos.dates = shortFormatStartAndEndDate(data.startDate, data.endDate)
    if (guestName !== 0) stayInfos.guest = guestName
    stayInfos.source = capitalizeFirstLetter(data.source)
    return this.formatStayStringToShowInTooltip(stayInfos)
  }
}
